import { Button, Popconfirm } from "antd";
import React from "react";

interface Props {
  id: string,
  onConfirm: (id: string) => any
};

const ConfirmDeleteButton: React.FC<Props> = ({ id, onConfirm }) => {
  return (
    <Popconfirm
      title='Are you sure you want to delete this record?'
      onConfirm={() => onConfirm(id)}
    >
      <Button danger>Delete</Button>
    </Popconfirm>
  )
}

export default ConfirmDeleteButton;
