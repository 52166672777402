import { Menu } from 'antd';
import React from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import NewSearch from './NewSearch';
import Overview from './Overview';

function pair(text: string, component: React.FC) {
  return { text, component };
}

const Navigation: React.FC = () => {
  const { pathname } = useLocation();
  const selectedKey = pathname === '/'
    ? '/overview'
    : pathname;

  const textLinksAndComponents = [
    pair('Overview', Overview),
    pair('New Search', NewSearch)
  ].map(({ text, component }) => {
    const linkTo = `/${text.toLowerCase().replace(' ', '-')}`;
    return { text, linkTo, component };
  });

  const menuItems = textLinksAndComponents.map(({ text, linkTo }) => (
      <Menu.Item key={linkTo} >
        <Link to={linkTo}>{text}</Link>
      </Menu.Item>
    ));

  const routes = [...textLinksAndComponents.map(({ linkTo, component }) => (
    <Route key={linkTo} path={linkTo} component={component} />
  )), <Route key={'/'} path={'/'} component={Overview} />];

  return (
    <>
      <Menu
        defaultSelectedKeys={['/overview']}
        selectedKeys={[selectedKey]}
        theme='dark'
        direction='ltr'
        mode='horizontal'
      >
        {menuItems}
      </Menu>

      <Switch>
        {routes}
      </Switch>
    </>
  );
}

export default Navigation;
