import { Button, DatePicker, Form, Input, Modal, Image, Row, Col, Typography, Checkbox } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { postSeekerSearchRequest } from "../effects";
import { NewSearchFormValues } from "../models";
import sirFoundalotImage from '../assets/sir-foundalot.png';
import { useHistory } from "react-router-dom";


const initialValues: NewSearchFormValues = {
  dateStolen: moment(),
  postcodeStolenNear: '',
  brand: '',
  category: '',
  colour: '',
  model: '',
  confirm: false,
};

const postcodeRegex = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

const NewSearch: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm<typeof initialValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const now = moment();

  const onFinish = (values: NewSearchFormValues) => {
    setLoading(true);
    const { confirm, dateStolen, postcodeStolenNear, ...item } = values;

    postSeekerSearchRequest({
      dateStolen: dateStolen.toDate(),
      postcodeStolenNear,
      item
    }).then(() => Modal
      .success({
        style: { justifyContent: 'center', justifyItems: "center" },
        icon: (<Image style={{ marginRight: '16px' }} width={200} src={sirFoundalotImage} />),
        content: 'Seeker search record created. We’re working hard to help find the item!',
        onOk: () => {
          form.resetFields();
          setLoading(false);
          history.push('/overview');
        }
      }))
      .catch(() => Modal
        .error({
          centered: true,
          content: 'Sorry, something went wrong when creating the search. '
          + 'We\'ll try our best to fix it. '
          + 'Don\'t worry, you haven\'t been charged.',
          onOk: () => {
            setLoading(false);
          }
        }));
  };

  const sideCol = <Col md={7} span={1}></Col>;

  return (
    <Row justify='center'>
      {sideCol}
      <Col md={10} span={22}>
        <Typography.Title level={3}>Start a new Seeker search</Typography.Title>
        <Form
          initialValues={initialValues}
          onFinish={onFinish}
          layout='vertical'
          form={form}
        >
          <Form.Item
            label='Category'
            name='category'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please tell us the type of item. e.g: Bike, Laptop, Phone.'
            }]}
          >
            <Input placeholder={'Bike'}></Input>
          </Form.Item>

          <Form.Item
            label='Brand'
            name='brand'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please tell us the brand of the item.'
            }]}
          >
            <Input placeholder={'Dolan'}></Input>
          </Form.Item>

          <Form.Item
            label='Model'
            name='model'
            rules={[{
              type: 'string',
              required: true,
              message: 'Please tell us the model of the item.'
            }]}
          >
            <Input placeholder={'Pre Cursa'}></Input>
          </Form.Item>

          <Form.Item
           label='Colour'
           name='colour'
           rules={[{
            type: 'string',
            required: true,
            message: 'Please tell us the colour of the item.'
          }]}
          >
            <Input placeholder={'Purple'}></Input>
          </Form.Item>

          <Form.Item
           label='Postcode where the item was last seen'
           name='postcodeStolenNear'
           rules={[{
            type: 'string',
            required: true,
            message: 'Please enter a valid postcode where the item was last seen.',
            transform: (value?: string) => value
              ?.toUpperCase()
              .trim()
              .replace(' ', ''),
            validator: (_, value) => {
               if (postcodeRegex.test(value)) return Promise.resolve();
               return Promise.reject('Invalid postcode.');
            },
          }]}
          >
            <Input placeholder={'BS1 5TR'}></Input>
          </Form.Item>

          <Form.Item
            label='Date the item was last seen'
            name='dateStolen'
            rules={[{
              type: 'date',
              required: true,
              message: 'Please tell us the date the item was last seen.',
            }]}
          >
            <DatePicker
              disabledDate={current => current.isAfter(now)}
              style={{ width: '100%' }}
            ></DatePicker>
          </Form.Item>

          <Form.Item
            name='confirm'
            valuePropName='checked'
            rules={[{
              required: true,
              transform: value => (value || undefined),
              type: 'boolean',
              message: 'Please accept the charge.'
            }]}
          >
            <Checkbox>
              <Typography.Text>
                Do you accept that submitting this form will incur a charge for a Seeker search?
              </Typography.Text>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button loading={loading} size={'large'} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
      {sideCol}
    </Row>
  )
};

export default NewSearch;