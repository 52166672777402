import { Badge } from "antd";
import { ClockCircleFilled } from "@ant-design/icons";
import moment from "moment";
import React from "react";

interface DaysRemainingProps {
  dateCreated: Date;
};

const DaysRemaining: React.FC<DaysRemainingProps> = ({ dateCreated }) => {
  const fourWeeksAgo = moment().subtract(4, 'weeks');
  const daysToGo = moment(dateCreated).diff(fourWeeksAgo, 'days');
  return daysToGo < 7 && daysToGo > -1
    ? <Badge count={<ClockCircleFilled style={{ color: 'orange' }} />}>
      <span>{daysToGo}</span>
    </Badge>
    : <>{daysToGo}</>;
}

export default DaysRemaining;