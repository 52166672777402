import { FetchSearchesResponse, SearchStatus, SeekerSearch } from "@seeker/schema";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { deleteSeekerSearch, fetchSeekerSearches } from "../effects";
import ConfirmDeleteButton from "./ConfirmDeleteButton";
import DaysRemaining from "./DaysRemaining";
import SearchDetailsDrawer from "./SearchDetailsDrawer";
import SearchStatusTag from "./SearchStatusTag";

const Overview: React.FC = () => {
  const [searchesData, setSearchesData] = useState<FetchSearchesResponse>({
    page: 1,
    searches: [],
    total: 0
  });
  const [page, setPage] = useState<number>(1);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [drawerSearch, setDrawerSearch] = useState<SeekerSearch>();
  const [userEmail, setUserEmail] = useState<string>('');

  useEffect(() => {
    const initEmail = async () => {
      setUserEmail((await Auth.currentSession())
        .getIdToken()
        .decodePayload()
        .email)
    }

    initEmail();
  }, [])

  useEffect(() => {
    const initSearches = async () => {
      setSearchesData(await fetchSeekerSearches(page));
    };

    initSearches();
  }, [page]);

  const deleteRecord = async (id: string) => {
    await deleteSeekerSearch(id);

    setSearchesData({
      ...searchesData,
      searches: searchesData.searches
        .filter(ss => (ss._id as any) !== id) || [],
    });
  };

  const columns: ColumnsType<SeekerSearch> = [
    {
      key: 'item',
      title: 'Item',
      dataIndex: 'item',
      render: ({ brand, colour, model }: SeekerSearch['item']) =>
        `${colour} ${brand} ${model}`
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status: SearchStatus) => (
        <SearchStatusTag searchStatus={status} />)
    },
    {
      key: 'daysRemaining',
      title: 'Days Remaining',
      dataIndex: 'dateCreated',
      render: (dateCreated: Date) => (
        <DaysRemaining dateCreated={dateCreated} />)
    },
    {
      key: 'areaOfTheft',
      title: 'Area of Theft',
      dataIndex: 'postcodeStolenNear',
    },
    {
      key: 'searchRadius',
      title: 'Search Radius',
      dataIndex: 'searchRadiusInKm',
      render: (radius: number) => `${radius} km`,
    },
    {
      key: 'createdBy',
      title: 'Created By',
      dataIndex: 'creator',
    },
    {
      key: 'group',
      title: 'Group',
      dataIndex: 'seekerGroup',
    },
    {
      key: 'searchId',
      title: 'Search ID',
      dataIndex: '_id',
    },
    {
      key: 'action',
      title: '',
      dataIndex: '_id',
      render: (id, ss) => ss.creator === userEmail
        ? (<ConfirmDeleteButton id={id} onConfirm={deleteRecord} />)
        : '',
    }
  ];

  return (
    <>
      <Table
        pagination={{
          current: page,
          pageSize: 15,
          total: searchesData?.total,
          onChange: (newPage) => setPage(newPage),
        }}
        dataSource={searchesData?.searches}
        columns={columns}
        rowKey={(ss) => !ss?._id
          ? btoa(JSON.stringify(ss))
          : ss?._id + ''
        }
        onRow={(search) => ({
          style: { cursor: 'pointer' },
          onClick: () => {
            setDrawerSearch(search);
            setDrawerVisible(true);
          }
        })}
      />
      <SearchDetailsDrawer
        visible={drawerVisible}
        search={drawerSearch}
        onClose={() => setDrawerVisible(false)}
      />
    </>
  )
}

export default Overview;
