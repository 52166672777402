import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { SeekerSearch } from '@seeker/schema';
import { Button, Col, DatePicker, Descriptions, Divider, Drawer, Form, Input, List, Modal, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { updateSeekerSearch } from '../effects';
import { useCognitoAttributes } from '../hooks';
import DaysRemaining from './DaysRemaining';
import SearchStatusTag from './SearchStatusTag';


export interface SearchDetailsDrawerProps {
  search?: SeekerSearch,
  visible: boolean,
  onClose: () => void,
};

const SearchDetailsDrawer: React.FC<SearchDetailsDrawerProps> = ({ search, visible, onClose }) => {
  const attributes = useCognitoAttributes();
  const now = moment();
  const [loading, setLoading] = useState('');
  const { postcodeStolenNear, searchRadiusInKm, dateStolen, possibleMatches, item } = search || {};

  function onFinish(newValues: any, action: 'save' | 'cancel') {
    setLoading(action);
    const { postcodeStolenNear, dateStolen, searchRadiusInKm, possibleMatches, ...item } = newValues;

    updateSeekerSearch(
      search?._id as any || '',
      action === 'save'
        ? {
          postcodeStolenNear,
          dateStolen: dateStolen?.toDate(),
          searchRadiusInKm,
          possibleMatches,
          item,
        }
        : { status: newValues.status }
    )
      .then(() => setLoading(''))
      .catch(() => Modal
        .error({
          centered: true,
          content: 'Sorry, something went wrong when updating the search. '
            + 'We\'ll try our best to fix it. ',
          onOk: () => {
            setLoading('');
          }
        }));
  }

  const content = attributes['custom:seekerGroup'] === 'found'
    ? (
      <Form
        onFinish={(values) => onFinish(values, 'save')}
        initialValues={{
          postcodeStolenNear,
          searchRadiusInKm,
          dateStolen: moment(dateStolen),
          possibleMatches,
          ...item,
        }}
      >
        <Form.Item required name='category' label='Item type'><Input /></Form.Item>
        <Form.Item required name='brand' label='Brand'><Input /></Form.Item>
        <Form.Item required name='model' label='Model'><Input /></Form.Item>
        <Form.Item required name='colour' label='Colour'><Input /></Form.Item>

        <Form.Item required name='postcodeStolenNear' label='Area of theft'><Input /></Form.Item >
        <Form.Item required name='dateStolen' label='Date of theft' >
          <DatePicker
            disabledDate={current => current.isAfter(now)}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item required name='searchRadiusInKm' label='Search radius'>
          <Input type='number' suffix='km' />
        </Form.Item>

        <Descriptions title='Search Details' layout='horizontal'>
          <Descriptions.Item label='Search ID'>{search?._id}</Descriptions.Item>
          <Descriptions.Item label='Status'>
            <SearchStatusTag searchStatus={search?.status} />
          </Descriptions.Item>
          <Descriptions.Item label='Creator'>{search?.creator}</Descriptions.Item>
          <Descriptions.Item label='Creator group'>{search?.seekerGroup}</Descriptions.Item>
          <Descriptions.Item label='Date of creation'>
            {moment(search?.dateCreated).format('DD MMM YYYY HH:mm')}
          </Descriptions.Item>
          <Descriptions.Item label='Days remaining'>
            <DaysRemaining dateCreated={search?.dateCreated || new Date()} />
          </Descriptions.Item>
        </Descriptions>

        <Form.Item>
          <Button loading={loading === 'save'} size={'large'} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>

        <Button
          loading={loading === 'cancel'}
          size={'large'}
          danger
          id='cancel-search'
          onClick={() => onFinish({ status: 'CANCELLED' }, 'cancel')}>
          Cancel Search
        </Button>


        <Divider />

        <Typography.Title level={3}>Matches</Typography.Title>
        <Form.List name='possibleMatches'>
          {(fields, { add, remove }) => (<>
            {fields.map((field, index) => (<Row justify='start' key={index}>
              <Col span={22}>
                <Form.Item {...field}>
                  <Input placeholder='https://ebay.com/itm/...' />
                </Form.Item>
              </Col>

              <Col span={2}>
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => remove(field.name)}
                />
              </Col>
            </Row>))}
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '100%' }}
              icon={<PlusOutlined />}
            >
              Add field
            </Button>
          </>)}
        </Form.List>
      </Form>)
    : (<>
      <Descriptions title='Item' layout='horizontal'>
        <Descriptions.Item label='Item type'>{search?.item.category}</Descriptions.Item>
        <Descriptions.Item label='Brand'>{search?.item.brand}</Descriptions.Item>
        <Descriptions.Item label='Model'>{search?.item.model}</Descriptions.Item>
        <Descriptions.Item label='Colour'>{search?.item.colour}</Descriptions.Item>
      </Descriptions>

      <Divider />

      <Descriptions title='Theft Details' layout='horizontal'>
        <Descriptions.Item label='Area of theft'>{search?.postcodeStolenNear}</Descriptions.Item>
        <Descriptions.Item label='Date of theft'>{moment(search?.dateStolen).format('DD MMM YYYY HH:mm')}</Descriptions.Item>
      </Descriptions>

      <Divider />

      <Descriptions title='Search Details' layout='horizontal'>
        <Descriptions.Item label='Search ID'>{search?._id}</Descriptions.Item>
        <Descriptions.Item label='Status'>
          <SearchStatusTag searchStatus={search?.status} />
        </Descriptions.Item>
        <Descriptions.Item label='Creator'>{search?.creator}</Descriptions.Item>
        <Descriptions.Item label='Creator group'>{search?.seekerGroup}</Descriptions.Item>
        <Descriptions.Item label='Search radius'>{search?.searchRadiusInKm + 'km'}</Descriptions.Item>
        <Descriptions.Item label='Date of creation'>
          {moment(search?.dateCreated).format('DD MMM YYYY HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label='Days remaining'>
          <DaysRemaining dateCreated={search?.dateCreated || new Date()} />
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <Typography.Title level={3}>Matches</Typography.Title>
      <List
        dataSource={search?.possibleMatches || []}
        renderItem={(matchUrl) => (
          <a
            href={matchUrl}
            target='_blank'
            rel='noopener noreferrer' >
            {matchUrl}
          </a>
        )}
      />
    </>);

  return (
    <Drawer visible={visible} onClose={onClose} width='80%' >

      {content}

    </Drawer>
  );
}

export default SearchDetailsDrawer;