import { FetchSearchesResponse, SeekerSearch } from "@seeker/schema";
import { Auth } from "aws-amplify";
import axios from "axios";
import { seekerConfig } from "../config";

const getIdToken = async () => (await Auth.currentSession())
  .getIdToken()
  .getJwtToken();

export async function postSeekerSearchRequest(body: Partial<SeekerSearch>) {
  const token = await getIdToken();
  const { status } = await axios.post(
    `${seekerConfig.apiBaseUrl}/searches`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

  return status
}

export async function fetchSeekerSearches(page?: number) {
  page = page || 1;

  const token = await getIdToken();
  return axios.get<FetchSearchesResponse>(
    `${seekerConfig.apiBaseUrl}/searches`,
    {
      params: { page },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  ).then(response => response.data);
}

export async function deleteSeekerSearch(searchId: string) {
  const token = await getIdToken();
  return axios.delete<SeekerSearch>(
    `${seekerConfig.apiBaseUrl}/searches/${searchId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  ).then(response => response.data);
}

export async function updateSeekerSearch(searchId: string, body: Partial<SeekerSearch>) {
  const token = await getIdToken();
  return axios.put(
    `${seekerConfig.apiBaseUrl}/searches/${searchId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(response => response.status);
}
