import { SearchStatus } from "@seeker/schema";
import { Tag } from "antd";
import React from "react";

interface SearchStatusProps {
  searchStatus?: SearchStatus;
}

const statusColourMap: Record<SearchStatus, string> = {
  NEW: 'processing',
  'IN PROGRESS': 'processing',
  CANCELLED: 'warning',
  'CLOSED NO MATCH': 'error',
  'CLOSED WITH MATCH': 'success',
};

const SearchStatusTag: React.FC<SearchStatusProps> = ({ searchStatus }) => {
  const colour = searchStatus
    ? statusColourMap[searchStatus]
    : 'default';

  return (
    <Tag color={colour}>{searchStatus || 'UNKNOWN'}</Tag>
  );
}

export default SearchStatusTag;
