export interface SeekerConfig {
  "apiBaseUrl": string,
  "aws_project_region": string,
  "aws_cognito_region": string,
  "aws_user_pools_id": string,
  "aws_user_pools_web_client_id": string,
  "oauth": {}
}

export const seekerConfig: SeekerConfig = (window as any)._awsConfig;
