import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

export function useCognitoAttributes() {
  const [attributes, setAttributes] = useState<Record<string, string>>({});

  useEffect(() => {
    const updateAttributes = async () => {
      const userInfo = await Auth
        .currentUserInfo()
        .catch(() => ({}));
      setAttributes(userInfo?.attributes);
    };

    updateAttributes();
  }, []);

  return attributes || {};
}