import React from 'react';
import { Image, Layout } from 'antd';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import './App.less';
import logo from './assets/found-logo.png';
import placeholderLogo from './assets/found-logo.webp';
import Navigation from './components/Navigation';
import { BrowserRouter } from 'react-router-dom';
import { seekerConfig } from './config';

Amplify.configure(seekerConfig);

function App() {
  const placeholder = <Image src={placeholderLogo} />;

  return (
    <Layout className='App'>
      <AmplifyAuthenticator usernameAlias='email'>
        <AmplifySignIn
          hideSignUp
          slot='sign-in'
          headerText='Sign in to Seeker by Found'
        >
          <Image
            preview={false}
            placeholder={placeholder}
            width="30%"
            src={logo}
            alt={'The "Found" logo'}
            slot='secondary-footer-content'
          />
        </AmplifySignIn>
        <BrowserRouter>
          <Navigation />
        </BrowserRouter>
      </AmplifyAuthenticator>
    </Layout>
  );
}

export default App;
